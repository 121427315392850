@import '../../themes/colors/colors.scss';

.create_account_wrapper {
  width: 100%;
  padding: 1rem 1rem;

  .account_details {
    border-radius: 0.625rem;
    margin-top: 1rem;
    border: 1px solid $secondary;

    .account_details_header {
      display: flex;
      justify-content: space-between;
      background-color: $secondary-light;
      border-radius: 0.625rem 0.625rem 0 0;
      padding: 1rem 1.5rem;
      width: 100%
    }

    .form_wrapper {
      padding: 1rem 1.5rem;
      padding-bottom: 60px;
      background-color: #ffff;
      border-radius: 0.625rem;

      .form_button_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
