@import '../../themes/colors/colors.scss';

.filled_btn {
  background-color: $secondary;
  color: white;
  border: 1px solid white !important;
  padding: 1.1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  &:hover {
    background-color: white !important;
    color: $secondary !important;
    border: 1px solid $secondary !important;
  }

  .btn_child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }
}

.full_btn {
  background-color: $secondary;
  color: white;
  padding: 1.1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  width: 100%;

  &:hover {
    background-color: white !important;
    color: $secondary !important;
  }

  .btn_child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }
}

.outlined_btn {
  background-color: white;
  color: $secondary;
  border: 1px solid $secondary;
  padding: 1.1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  &:hover {
    background-color: $secondary !important;
    color: white !important;

  }

  .btn_child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }
}

.white_btn {
  background-color: white;
  padding: 1.1rem;
  border: 1px solid $LightGray;
  box-shadow: 0 0 0 0 black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  .btn_child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }
}
