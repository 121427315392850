@import "../../themes/colors/colors.scss";


.loading_space {

    width: 100%;
    justify-content: center;
    margin-top: 5rem;
}

.user_details_wrapper {
    border-radius: 0.625rem;
    border: 0.06rem solid $secondary;
    margin-top: 1rem;

    .user_details_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 0.625rem 0.625rem 0 0;
        padding: 1.3rem 1.5rem;
    }

    .form_wrapper {
        padding: 1rem 1.5rem;
        padding-bottom: 60px;
        background-color: #ffff;
        border-radius: 0.625rem;

        .form_button_wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

        }

        .form_required_field {
            color: $Red;
            margin-right: 0.25rem;
        }
    }
}