.dashboard_header_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.1rem 3rem;
  height: auto;


  .header_title_wrapper {
    width: 100%;
    display: flex;
    margin: 0rem;
    justify-content: space-between;
    min-height: 4rem;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .header_title {
      text-transform: capitalize;

    }
  }
}
