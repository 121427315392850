@import './themes/colors/colors.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .dashboard_wrapper {
    height: 100%;
    overflow-y: hidden;
    overflow: hidden;

    .ant-table-thead .ant-table-cell {
      background-color: $secondary-light !important;
      border-bottom: 0.12rem solid $secondary !important;
    }

    .ant-menu-item-selected {
      background-color: $secondary-light !important;
      color: $secondary;
      border-right: 0.3rem solid $secondary;
      border-radius: 0;
    }

    .layout_padding {
      padding: 1rem 2.5rem
    }
  }
}

/* scrollbar.css */
/* Track */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #ebb471, #f08706, #ebb471);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0e1ce;
}
