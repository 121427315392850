@import '../../themes/colors/colors.scss';

.new_user_wrapper {
  width: 100%;
  padding: 1rem 2rem;

  .new_user_details {
    border-radius: 0.625rem;
    margin-top: 1rem;
    border: 1px solid $secondary;

    .new_user_header {
      display: flex;
      justify-content: space-between;
      background-color: $secondary-light;
      border-radius: 0.625rem 0.625rem 0 0;
      padding: 1rem 1.5rem;
      width: 100%
    }

    .form_wrapper {
      padding: 1rem 1.5rem;

      .form_button_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}