@import '/src/themes/colors/colors.scss';



.bell_div {
  position: relative;

  .red_div {
    position: absolute;
    background-color: $Red;
    border-radius: 100rem;
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-content: center;
    top: -0.8rem;
    right: -0.45rem;

    .text {
      font-size: 0.7rem;
      color: $white;
    }
  }
}
