.settings_wrapper {
  width: 100%;
  padding: 1rem 2.5rem 3.5rem 2.5rem;

  .settings_content_headings_wrapper {
    width: 100%;
    padding: 0rem 2rem 0rem 2rem;
    background-color: white;
    border-radius: 0.625rem;

    .user_list_content_headerings {
      display: flex;
      justify-content: space-between;
      border-radius: 0.625rem 0.625rem 0 0;
      padding: 1rem;
      width: 100%;
    }

    .settings_items_heading {
      width: 100%;
      background-color: white;
      padding: 0.2rem 2rem;
      border-radius: 0.625rem;
      display: flex;
      justify-content: space-between;

      .pass_input {
        padding: 0.4rem;
        background-color: white;
        border-radius: 2px;
      }

      .pass_change_btn_wrapper {
        margin: 1rem 0;
        display: flex;
        justify-content: flex-end;
      }

      .pass_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .form_button_wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
