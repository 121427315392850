.settings_items_heading {
  width: 100%;
  background-color: white;
  padding: 0.2rem 2rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: space-between;

  .pass_input {
    padding: 0.4rem;
    background-color: white;
    border-radius: 2px;
  }

  .pass_change_btn_wrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }

  .pass_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }
}
