@import '/src/themes/colors/colors.scss';

.user_list_wrapper_header {
  width: 100%;
  text-transform: capitalize;
  background-color: white;
  position: relative;

  .user_list_header {
    width: 100%;
    background-color: white;
    padding: 0rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .search_div {
      border-radius: 0.2rem;
      justify-content: space-around;
      padding: 0.05rem;
      display: flex;
      align-items: flex-start;

      .search_input {
        border-width: 0;
        border-color: transparent;
        box-shadow: none;
        background: transparent;

      }
    }
  }
}

.user_list_wrapper {
  width: 100%;
  padding: 2rem 2rem 2rem 2rem;
  text-transform: capitalize;

  .user_list_content_headerings_wrapper {
    width: 100%;
    padding: 0rem 2rem;
    background-color: white;
    border-radius: 0.625rem;


    .user_list_content_headerings {
      display: flex;
      justify-content: space-between;
      border-radius: 0.625rem 0.625rem 0 0;
      padding: 0rem 1rem 0rem 1rem;
      height: 4.5rem;
      width: 100%;

      .search_div {
        border-radius: 0.2rem;
        justify-content: space-around;
        padding: 0.05rem;
        display: flex;
        align-items: flex-start;

        .search_input {
          border-width: 0;
          border-color: transparent;
          box-shadow: none;
          background: transparent;

        }
      }
    }

    .user_list_items_heading {
      width: 100%;
      background-color: white;
      padding: 0rem 2rem;
      border-radius: 0.625rem;
      display: flex;
      justify-content: space-between;
      margin-top: -1rem;

      .font_style {
        color: $greyHeading;
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
  }
}
