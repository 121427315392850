@import '../../themes/colors/colors.scss';

$light-gray: #D9D9D9;
$secondary: #F7941D;

.search_input {
  border: 0.1rem solid $light-gray;
  width: 500px;

  &:hover {
    border: 0.1rem solid $secondary;
  }

  &:active {
    border: 0.1rem solid $secondary;
    outline: none;
  }

  &:focus {
    border: 0.1rem solid $light-gray;
    outline: none;
  }
}
