@import '/src/themes/colors/colors.scss';

.login_form_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;


  .login_header {
    background-color: $Blue;
    width: 100%;
    height: 4rem;
    position: absolute;
    top: 0;
  }

  .login_form_wrapper {
    box-shadow: 0px 5px 5px rgba(138, 137, 137, 0.5);
    padding: 1rem;
    border-radius: 2px;
    width: 25rem;

    .login_form {
      .login_inputs {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        background-color: white;

        .site_form_item_icon {
          color: gray;
          margin-right: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  .underline_text {
    font-size: 0.8rem;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
}

.login_error_text {
  color: red;
  font-size: 0.8rem;
  margin-top: -1rem;
}
