$gray: #757575;
$secondary: #F7941D;

.switch_form_wrapper {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    .switch_form_item {
        background-color: $gray;

        &.active {
            background-color: $secondary;
        }
    }
}
