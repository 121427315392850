@import '../../themes/colors/colors.scss';

.table_wrapper {


  .text_primary {
    color: $secondary;
  }

  .text_secondary {
    color: $gray;
  }

  .flex_align_center {
    display: flex;
    align-items: center;
  }

  .bg_success {
    background-color: $Green;
  }

  .bg_secondary {
    background-color: $LightGray;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .editing {
    background-color: $editing !important;
  }
}
